import { Controller } from "@hotwired/stimulus";
import geocoderLoader from "../../maps/mapbox-geocoder"
import mapboxLoader from "../../maps/mapbox"
import AddressWrapper from "../../maps/address-wrapper"

export default class extends Controller {
  static targets = ["addressSearch", "addressInputGeocoder", "addressSearchControl", "addressInputControl"]
  connect () {
    this._initializeGeocoders()
  }
  _initializeGeocoders () {
    mapboxLoader.then((mapboxgl) => {
      geocoderLoader.then((MapboxGeocoder) => {
        this.geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          types: 'address',
          localGeocoder: this.addManualAddressEntryToResults,
          language: 'en-US',
          limit: 6,
          placeholder: 'Search for address'
        })
        this.geocoder.addTo(this.addressSearchTarget)
        this.geocoder.on('results', results => this.formatResults(results))
        this.geocoder.on('result', result => this.selected(result))

        this.addressInputGeocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          types: 'address,place,region,country',
          language: 'en-US'
        })
        this.addressInputGeocoder.on('result', (result) => this.selectedFromAddressInput(result))
        this.addressInputGeocoder.addTo(this.addressInputGeocoderTarget)
      })
    })
  }
  toggleAddressInput() {
    this.element.classList.toggle('use-address-input')
    if (this.element.classList.contains('use-address-input')) {
      this.addressInputControlTarget.checked = true
    } else {
      this.addressSearchControlTarget.checked = true
    }  
  }
  selected (selection) {
    if (selection.result.id == 'fakeID') {
      this.toggleAddressInput()
      this.geocoder.clear()
    } else {
      this.dispatch("selected-location", {detail: {selection: this.formatSelection(selection.result)}})
    }
  }
  selectedFromAddressInput (selection) {
    const address = this.addressInput
    const result = selection.result
    let addressSelection = {
      street: address.street1,
      city: address.city,
      region: address.region,
      countryCode: address.country,
      country: address.country,
      postcode: address.postal,
      lnglat: result.center,
      lng: result.center[0],
      lat: result.center[1]
    }
    this.dispatch("selected-location", {detail: {selection: addressSelection }})
  }
  geocodeAddressInput (e) {
    this.addressInput = e.address
    let {street1, city, region, country, postal} = this.addressInput
    let query = [street1, city, region, country, postal].filter((str) => str).join(' ')
    this.addressInputGeocoder.setCountries(country)
    this.addressInputGeocoder.query(query)
  }
  formatSelection(result) {
    const address = new AddressWrapper(result)
    const selection = {
      street: address.street,
      city: address.city,
      region: address.region,
      countryCode: address.countryCode,
      country: address.country,
      postcode: address.postcode,
      lnglat: address.lnglat,
      lng: address.lng,
      lat: address.lat
    }
    return selection
  }
  
  formatResults(results) {
    if (results.features.length > 1) {
      if (results.features[0].id == 'fakeID') {
        let fakeResult = results.features.shift()
        results.features.splice(5,0,fakeResult)
        return results
      }
    }
  }
  
  addManualAddressEntryToResults(queryString) {
    if (queryString.length > 8) {
      return [
        {
          "type": "Feature",
          "id": "fakeID",
          "text": "Can't find address",
          "place_name": "Can't find your address?, Click here to enter it manually",
          "place_type": [ "place" ],
          "center": [-97.7559964, 30.3071816],
          "geometry": {
            "type": "Point",
            "coordinates": [-97.7559964, 30.3071816]
          },
          "properties": {
            "title": "Austin",
            "type": "city",
            "score": 0.00000000001
          },
          "context": [
            {
              "id": "province.293",
              "text": "Texas"
            },
            {
              "id": "country.51",
              "text": "United States"
            }
          ]
        }
      ]
    }
  }
}
